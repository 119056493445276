<template>
  <div class="video_details" @click="isCollectionShow = false">
    <div class="video_details_content_box">
      <div class="content">
        <div class="conent_top">
          <span @click="goBack" class=" hand">&lt;&lt; 返回上级</span>
          <div class="video_type" v-if="videoObj.modularType === 'video'">
            <div class="type_box">
              <span>当前学科分类: {{videoObj.videoType}}</span>
            </div>
          </div>
        </div>
        <div class="content_left">

          <div class="video_box">
            <img v-if="videoDetails.isVip === 1" class="video_vip_icon"
                 src="@/assets/img/infoCollege/video_vip_icon.png" alt="">
            <div id="video"></div>
          </div>
          <div class="introduction_box">
            <div class="video_title_box">
              <h3 class="video_title">{{videoDetails.title}}</h3>
              <div v-if="videoObj.modularType === 'video'" class="btn_box">
                <span @click="callCollect" class="collection  hand" v-if="isCollection">
                  <i class="iconfont icon-shoucang"></i>
                  已收藏
                </span>
                <collection v-else ref="collection" :itemId="videoDetails.id+''" :category="3"
                            @upDataType="collectStatus" />
              </div>
            </div>
            <p v-if="videoObj.modularType === 'video'">{{videoDetails.courceIntroduction}}</p>
            <img class="introduction_img" v-else :src="videoDetails.meetingIntroductionPic"
                 alt="课程简介">
          </div>
        </div>

        <div class="content_right loading" v-if="videoObj.optionId">

          <div class="content_right_top">
            <span>相关视频</span>
            <span @click="onReplaceVideoList" v-if="isShow" class=" hand last_children"><i
                 class="el-icon-refresh"></i>换一批</span>
          </div>

          <!-- 课程列表 -->
          <ul v-if="videoObj.modularType === 'video'" class="video_list">
            <li @click="onVideoListClick(item)" v-for="item in videoList" :key="item.id">
              <div class="img_box">
                <img class="videoPic" :src="item.videoPic" alt="">
              </div>
              <p>{{item.title}}</p>
              <span class="time">{{item.videoDuration}}分钟</span>
              <i class="iconfont icon-dangqian icon" v-if="item.id === videoDetails.id"></i>
              <img v-if="item.isVip===1" class="vip_icon"
                   src="@/assets/img/infoCollege/video_vip_icon.png">
            </li>
          </ul>
          <!-- 回放列表 -->
          <ul v-else class="video_list">
            <li @click="onPlaybackListClick(item)" v-for="item in PlaybackList" :key="item.id">
              <div class="img_box">
                <img class="videoPic" :src="item.meetingPic" alt="">
              </div>
              <p>{{item.title}}</p>
              <span class="time">会议时间{{ $initTime(item.meetingTime,'yyyy-MM-DD HH:mm')}}</span>
              <i class="iconfont icon-dangqian icon" v-if="item.id === videoDetails.id"></i>
              <img class="vip_icon" src="@/assets/img/infoCollege/playback_icon.png">
            </li>
            <li v-if="isShow" class="last_children ">
              <span @click="onReplaceVideoList" class=" hand"><i
                   class="el-icon-refresh"></i>换一批</span>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { getVideoDetails, getVideoList, getPlaybackDetails, getPlaybackList } from '@/api/infoCollege.js'
import { collectStatus, callCollect } from '@/api/article.js'
import { mapGetters } from 'vuex'
import Player from 'xgplayer'
export default {
  data () {
    return {
      videoObj: {}, // 视频列表跳转过来的参数
      videoDetails: {}, // 视频详情
      pageObj: {
        pageNum: 1,
        pageSize: 4
      },
      videoList: [], // 课程视频列表
      PlaybackList: [], // 回放列表
      player: {}, // 播放器实例
      isShow: true, // 换一换状态位
      isCollection: false, // 收藏状态
      isCollectionShow: false // 收藏弹框状态
    }
  },
  methods: {
    // 回访列表点击事件
    onPlaybackListClick (playback) {
      if (playback.id === this.videoDetails.id) return this.$message.warning('已展示当前视频')
      this.videoObj.videoId = playback.id
      this.player.destroy()
      document.documentElement.scrollTop = 0
      this.getPlaybackDetails()
    },
    // 获取回放列表
    async getPlaybackList () {
      const res = await getPlaybackList(this.pageObj)
      this.PlaybackList = res.data
    },
    // 获取回访详情
    async getPlaybackDetails () {
      const res = await getPlaybackDetails({ playbackId: this.videoObj.videoId })
      this.videoDetails = res.data
      this.initVideo(this.videoDetails.meetingPic, this.videoDetails.meetingUrl)
    },
    // 课程取消收藏
    callCollect () {
      this.$confirm('确认取消收藏该视频吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        await callCollect({
          category: 3,
          itemId: this.videoDetails.id,
          userId: this.userId
        })
        this.$message.success('您已取消收藏')
        this.isCollection = false
      }).catch((e) => {
        if (e !== 'cancel') return this.$message.error('操作失败')
      })
    },
    // 课程收藏视频
    async addCollect (event) {
      this.isCollectionShow = true
      const e = event || window.event
      const x = e.target.offsetLeft
      const y = e.target.offsetTop + e.target.offsetHeight + 20
      // console.log(x, y)
      this.$nextTick(() => {
        this.$refs.collection.setPosition(x, y, 3)
      })
    },
    // 课程获取收藏状态
    async collectStatus () {
      const res = await collectStatus({
        category: 3,
        itemId: this.videoDetails.id,
        userId: this.userId
      })
      this.isCollection = res.data
    },
    // 课程列表点击事件 isHy
    onVideoListClick (video) {
      if (video.id === this.videoDetails.id) return this.$message.warning('已展示当前视频')
      if (video.isVip === 1 && !this.userInfo.isHy) {
        return this.$notify({
          title: '权益提示',
          message: '此视频为vip专享, 请前往APP开通会员',
          type: 'warning'
        })
      }
      this.videoObj.videoId = video.id
      this.player.destroy()
      document.documentElement.scrollTop = 0
      this.getVideoDetails()
    },
    // 换一换
    onReplaceVideoList () {
      this.pageObj.pageNum++
      if (this.videoObj.modularType === 'video') {
        this.getVideoList()
      } else if (this.videoObj.modularType === 'playback') {
        this.getPlaybackList()
      }
    },
    // 初始化播放器
    initVideo (imgUrl, videoUrl) {
      this.player = new Player({
        id: 'video', // 每个播放器的dom标识
        url: videoUrl, // 视频路径
        poster: imgUrl, // 封面图
        width: '100%', // 播放器宽度
        height: 'calc(100vh - 120px)', // 播放器高度 675px
        volume: 0.6, // 初始音量
        pip: true,
        autoplay: true, // 自动播放
        playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速
        defaultPlaybackRate: 1 // 默认倍速1
      })
    },
    // 获取课程视频列表
    async getVideoList () {
      try {
        const res = await getVideoList({
          ...this.pageObj,
          categoryId: this.videoObj.optionId
        })
        if (res.data.length < this.pageObj.pageSize) {
          this.isShow = false
        } else {
          this.isShow = true
        }
        this.videoList = res.data
      } catch (error) {
        this.$message.error('获取视频列表失败')
      }
    },
    // 获取课程视频详情
    async getVideoDetails () {
      try {
        const res = await getVideoDetails({ videoId: this.videoObj.videoId })
        this.videoDetails = res.data
        this.initVideo(this.videoDetails.videoPic, this.videoDetails.videoUrl)
        this.collectStatus()
      } catch (error) {
        this.$message.error('获取视频详情失败')
      }
    },
    // 返回上层
    goBack () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['userId', 'userInfo'])
  },
  created () {
    this.videoObj = JSON.parse(sessionStorage.getItem('videoObj') || '{}')
    if (!this.videoObj.modularType) return this.$router.push('/infoCollege')
    if (this.videoObj.modularType === 'video') {
      this.getVideoDetails()
      if (this.videoObj.optionId) {
        this.getVideoList()
      }
    } else if (this.videoObj.modularType === 'playback') {
      this.getPlaybackDetails()
      // this.getPlaybackList()
    }
  }
}
</script>
<style lang="less" scoped>
.video_details {
  width: 100%;
  min-height: 100%;
  .video_details_content_box {
    width: 100%;
    background-color: #f5f7fb;
    min-height: calc(100% - 82px);
    .content {
      width: 1200px;
      min-height: 100%;
      margin: auto;
      padding: 30px 0;
      .conent_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 600;
        color: #4b639f;
      }
      .content_left {
        background-color: #fff;
        border-radius: 0 0 2px 2px;
        .collection {
          padding: 6px 15px;
          border-radius: 3px;
          border: 1px solid #4b639f;
          color: #4b639f;
          transition: all 0.3s;
          &:hover {
            background-color: #4b639f;
            color: #fff;
          }
          i {
            position: relative;
            top: -1px;
            vertical-align: middle;
            margin-right: 3px;
          }
        }
        .video_box {
          position: relative;
          border-bottom: 1px solid #e8e8e8;
          #video {
            min-height: 400px;
          }
          .video_vip_icon {
            height: 18px;
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 99;
          }
        }
        .introduction_box {
          .video_title_box {
            display: flex;
            .btn_box {
              flex-shrink: 0;
              text-align: end;
              /deep/.collect_btn {
                margin-right: 0;
                font-size: 12px;
                width: 60px;
                height: 26px;
                line-height: 26px;
                .iconfont {
                  font-size: 14px;
                }
              }
            }
          }
          .video_title {
            flex: 1;
            font-size: 18px;
            font-weight: 600;
            color: #4b639f;
            margin: 0px 0 10px;
            word-break: break-all;
          }
          padding: 20px;
          p {
            line-height: 25px;
            font-size: 14px;
          }
          .introduction_img {
            width: 100%;
          }
        }
      }

      .content_right {
        .content_right_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 14px;
          .last_children {
            background-color: transparent;
            &:hover {
              box-shadow: 0 0 0 0 transparent;
              span {
                color: #4b639f;
              }
            }
            span {
              font-size: 16px;
              i {
                margin-right: 5px;
              }
            }
          }
        }
        .video_list {
          position: relative;
          width: 100%;
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          li {
            position: relative;
            display: flex;
            width: 23.7%;
            height: 120px;
            padding: 20px;
            margin: 0 20px 20px 0;
            border-radius: 2px;
            background-color: #fff;
            &:nth-child(4n + 0) {
              margin-right: 0;
            }
            &:hover {
              box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
              .videoPic {
                transform: scale(1.15);
              }
            }
            .img_box {
              width: 90px;
              margin-right: 15px;
              overflow: hidden;
              flex-shrink: 0;
            }
            .videoPic {
              width: 90px;
              transform: scale(1.01);
              transition: transform 0.4s;
            }
            .icon {
              position: absolute;
              top: -10px;
              right: 0;
              font-size: 32px;
              color: #4b639f;
            }
            p {
              line-height: 20px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
            .time {
              position: absolute;
              bottom: 8px;
              right: 10px;
              font-size: 12px;
              color: #9195a3;
            }
            .vip_icon {
              position: absolute;
              left: 20px;
              bottom: 8px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
